<template>
  <div class="admin-contract">
    <div class="header">
      <div class="search">
        <van-dropdown-menu>
          <van-dropdown-item class="menu-item"
                             v-model="searchType"
                             :options="searchTypeOptions" />
        </van-dropdown-menu>
        <van-search class="search-content"
                    v-model.trim="searchContent"
                    @search="onSearch"
                    placeholder="请输入搜索关键词" />
        <div class="search-btn"
             @click="onSearch">搜索</div>
      </div>
      <div class="filter">
        <div class="filter-item"
             @click="folderClick">
          <span>{{ foldersComputed }}</span>
          <van-icon name="arrow-down" />
        </div>
        <div class="filter-item"
             @click="contractTypeClick">
          <span>{{ contractTypeComputed }}</span>
          <van-icon name="arrow-down" />
        </div>
        <div class="filter-item"
             @click="statusClick">
          <span>{{ contractStatusComputed }}</span>
          <van-icon name="arrow-down" />
        </div>
      </div>
      <van-popup class="folderDialog"
                 v-model="showFolderDialog"
                 position="left"
                 :style="{ height: '100%', width: '80%' }">
        <div class="title">文件夹</div>
        <div class="folders"
             v-if="folders.length">
          <div class="folder-item"
               :class="{ active: item.active }"
               v-for="item in folders"
               :key="item.id"
               @click="folderItemClick(item)">
            {{ item.name }}
          </div>
        </div>
        <div class="no-data"
             v-if="!folders.length">暂无数据</div>
        <van-pagination class="pagination"
                        v-model="folderPager.pageNumber"
                        :total-items="folderPager.totalRecords"
                        :items-per-page="folderPager.pageSize"
                        @change="pageChange" />
      </van-popup>
      <van-popup v-model="showContractType"
                 round
                 position="bottom">
        <custom-popup show-toolbar
                      title="合同类型"
                      :columns="contractTypes"
                      swipe-duration="500"
                      value-key="value"
                      @confirm="contractTypeConfirm"
                      @cancel="showContractType = false"></custom-popup>
      </van-popup>
      <van-popup v-model="showContractStatus"
                 round
                 position="bottom">
        <custom-popup title="合同状态"
                      :columns="contractStatus"
                      swipe-duration="500"
                      value-key="value"
                      @confirm="contractStatusConfirm"
                      @cancel="showContractStatus = false"></custom-popup>
      </van-popup>
    </div>
    <div class="main">
      <div class="list">
        <van-list :immediate-check="false"
                  v-model="listStatus.loading"
                  :finished="listStatus.finished"
                  @load="onLoad">
          <div class="list-item"
               v-for="item in list"
               :key="item.id"
               @click="itemClick(item.id)">
            <item-card class="list-item"
                       :title="item.name"
                       :status="item.documentStatus">
              <div class="content">
                <div class="content-row">
                  <div class="content-item">
                    <span class="name">合同编号:</span>
                    <span class="value">{{ item.serialNumber }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="content-item">
                    <span class="name">合同金额:</span>
                    <span class="value">{{ item.amount }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="content-item">
                    <span class="name">甲方:</span>
                    <span class="value">{{ item.sponsorName }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="content-item">
                    <span class="name">乙方:</span>
                    <span class="value">{{ item.participatorName }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="content-item">
                    <span class="name">合同签订日期:</span>
                    <span class="value">{{ item.signedDate }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="content-item">
                    <span class="name">合同起始日期:</span>
                    <span class="value">{{ item.startDate }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="content-item">
                    <span class="name">合同终止日期:</span>
                    <span class="value">{{ item.endDate }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="content-item">
                    <span class="name">合同类型:</span>
                    <span class="value">{{ item.type }}</span>
                  </div>
                </div>
              </div>
            </item-card>
          </div>
          <template #finished>
            <div v-if="list.length">没有更多了</div>
          </template>
        </van-list>
      </div>
      <van-empty description="暂无数据"
                 v-if="!list.length" />
    </div>

    <div class="footer">
      <van-button class="add-btn"
                  type="info"
                  round
                  @click="addContract">新增合同</van-button>
    </div>
  </div>
</template>

<script>
import "@/assets/less/views/contract/adminContract.less";
import Filter from "ldap-filters";
import { SUCCESS_CODE } from "@/apis/notification";
import { flatMap, find } from "lodash-es";
export default {
  name: "AdminContract",
  data() {
    return {
      searchType: "name",
      searchContent: "",
      searchTypeOptions: [{ text: "合同名称", value: "name" }],
      filter: {
        categoryId: null,
        type: null,
        status: null
      },

      showFolderDialog: false,
      folders: [],
      folderPager: {
        pageNumber: 1,
        pageSize: 10,
        totalRecords: 0
      },

      showContractType: false,
      contractTypes: [],
      contractStatus: [],
      showContractStatus: false,
      /* contractStatus: [
        { label: "所有", value: "all" },
        { label: "未生效", value: "UNEFFECTIVE" },
        { label: "已生效", value: "EFFECTIVE" },
        { label: "已过期", value: "EXPIRED" },
        { label: "已删除", value: "DELETED" }
      ], */

      listStatus: {
        loading: true,
        finished: false
      },

      list: [],
      page: {
        pageNumber: 0,
        pageSize: 10
      }
    };
  },
  computed: {
    foldersComputed() {
      if (!this.folders.length) return "文件夹";
      let item = find(this.folders, item => item.id == this.filter.categoryId);
      return item ? item.name : "文件夹";
    },
    contractTypeComputed() {
      if (!this.contractTypes.length) return "合同类型";
      let item = find(this.contractTypes, item => item.value == this.filter.type);
      return item ? item.value : "合同类型";
    },
    contractStatusComputed() {
      if (!this.contractStatus.length) return "合同状态";
      let item = find(this.contractStatus, item => item.value == this.filter.status);
      return item ? item.value : "合同状态";
    }
  },
  methods: {
    getAdminContractListApi() {
      let params = {
        pageNumber: this.page.pageNumber,
        pageSize: this.page.pageSize,
        type: this.filter.type,
        documentStatus: this.filter.status,
        categoryId: this.filter.categoryId
      };
      if (this.searchContent) {
        params.name = this.searchContent;
      }

      return this.$api.getAdminContractList({ params });
    },
    getCategoies() {
      let params = {
        pageNumber: this.folderPager.pageNumber - 1,
        pageSize: this.folderPager.pageSize
      };
      return this.$api.getCategoies({ params });
    },
    async getAdminContractList(sign = false) {
      let ret = await this.getAdminContractListApi();
      if (ret.code != SUCCESS_CODE) return;
      if (sign) {
        this.list = this.list.concat(ret.data);
      } else {
        this.list = ret.data;
      }
      this.listStatus.loading = false;
      this.handlePagination(ret.totalPages);
    },
    async getFolders(isLoad = true) {
      let ret = await this.getCategoies();
      if (ret.code != SUCCESS_CODE) return;
      ret.data.forEach(item => {
        item.active = item.id == this.filter.categoryId ? true : false;
      });
      this.folders = [{ id: null, name: "所有", active: this.filter.categoryId == null ? true : false }, ...ret.data];
      this.folderPager.totalRecords = ret.totalRecords;
      /* if (ret.data.length && isLoad) {
        this.filter.categoryId = ret.data[0].id;
        this.folders[0].active = true;
        this.getAdminContractList();
      } else {
        this.listStatus.finished = true;
      } */
    },
    async getDictsList() {
      let query = "name[sin]合同类型,合同状态";
      let ret = await this.$api.getDictsList({
        params: {
          query
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      this.contractTypes = [{ id: null, value: "所有" }, ...ret.data["合同类型"][0].dictOptions];
      this.contractStatus = [{ id: null, value: "所有" }, ...ret.data["合同状态"][0].dictOptions];
    },
    handlePagination(totalPages) {
      if (this.page.pageNumber + 1 < totalPages) {
        // 有更多数据
        this.page.pageNumber += 1;
      } else {
        this.listStatus.finished = true;
      }
    },
    folderClick() {
      this.showFolderDialog = true;
    },
    contractTypeClick() {
      this.showContractType = true;
    },
    statusClick() {
      this.showContractStatus = true;
    },
    folderItemClick(item) {
      this.filter.categoryId = item.id;
      this.folders.forEach(item => (item.active = false));
      item.active = true;
      this.getInitialData();
      this.showFolderDialog = false;
    },
    contractTypeConfirm(arg) {
      this.filter.type = arg.id ? arg.value : null;
      this.getInitialData();
      this.showContractType = false;
    },
    contractStatusConfirm(arg) {
      this.filter.status = arg.id ? arg.value : null;
      this.getInitialData();
      this.showContractStatus = false;
    },
    onSearch() {
      this.getInitialData();
    },
    getInitialData() {
      window.scroll({ top: 0, behavior: "instant" });
      this.listStatus.loading = true;
      this.listStatus.finished = false;
      this.page.pageNumber = 0;
      this.getAdminContractList();
    },
    onLoad() {
      this.getAdminContractList(true);
    },
    pageChange() {
      this.getFolders(false);
    },
    itemClick(id) {
      this.$router.push({
        name: "classificationEdit",
        query: {
          id,
          documentCategoryId: this.filter.categoryId
        }
      });
    },
    addContract() {
      this.$router.push({
        name: "classificationEdit",
        query: {
          documentCategoryId: this.filter.categoryId
        }
      });
    },
    parseStatus(arg) {
      let status = {
        UNEFFECTIVE: "未生效",
        EFFECTIVE: "已生效",
        EXPIRED: "已过期",
        DELETED: "已删除"
      };
      return status[arg];
    },
    init() {
      this.getInitialData();
    }
  },
  created() {
    this.getDictsList();
    this.getFolders();
    this.getAdminContractList();
  }
};
</script>

<style></style>
